<template>
  <div>
    <!-- 查看订单详情 -->
    <el-dialog
    :title="$t('commonInfo.orderDeList')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="1000px"
    :before-close="handleDialogClose"
    >
      <el-descriptions style="margin-bottom: 20px;" :column="2" border :labelStyle="{'width':'180px'}"
      	:title="$t('commonInfo.orderDetails')">
      	<el-descriptions-item :label="$t('commonInfo.orderNoColumn')">{{orderDetailsList.awbNo}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('commonInfo.clientOrderNoColumn')">{{orderDetailsList.clientAwbNo}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('commonInfo.packageAmount')">{{orderDetailsList.packageAmount}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('commonInfo.weightKG')">{{orderDetailsList.billingWeight}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('commonInfo.chargeVolumeCbm')">{{orderDetailsList.billingVolume}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('clientInfoPage.clientShortName')">{{orderDetailsList.clientShortName}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.goodCnDesc')" span="2">{{orderDetailsList.productCnDesc}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.goodEnDesc')" span="2">{{orderDetailsList.productEnDesc}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.cnConsignorWh')">{{orderDetailsList.cnConsignorWh}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.deCountry')">{{orderDetailsList.destCountry}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.deType')">{{orderDetailsList.destType}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.goodType')">{{orderDetailsList.productType}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('commonInfo.statusColumn')">{{orderDetailsList.trackingStatus}}
      	</el-descriptions-item>
      	<el-descriptions-item v-if="orderDetailsList.awbUrl != '' && orderDetailsList.awbUrl != null"
      		:label="$t('commonInfo.faceNoodles')">
      		<el-link type="primary" :href="orderDetailsList.awbUrl" target="_blank">
      			{{$t('commonInfo.actionDownload')}}</el-link>
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('commonInfo.createTimeColumn')">{{orderDetailsList.initTime}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('B2BOrder.examineStatus')">{{orderDetailsList.reviewStatus}}
      	</el-descriptions-item>
      	<el-descriptions-item :label="$t('chinaWhB2BOrderPage.customsDeclaration')"><span>{{orderDetailsList.customsDeclarationFlag==true?$t('chinaWhB2BOrderPage.officialCD'):$t('chinaWhB2BOrderPage.unofficialCD')}}</span></el-descriptions-item>
				<el-descriptions-item :label="$t('chinaWhB2BOrderPage.containWoodOrBamboo')"><span>{{orderDetailsList.containWoodOrBambooFlag===true?$t('commonInfo.yesBtn'):$t('commonInfo.noBtn')}}</span></el-descriptions-item>
				<el-descriptions-item :label="$t('chinaWhB2BOrderPage.shipType')"><span>{{orderDetailsList.shipType===2?$t('chinaWhB2BOrderPage.fastShip'):$t('chinaWhB2BOrderPage.normalShip')}}</span></el-descriptions-item>
      	<el-descriptions-item v-if="orderDetailsList.skuListLink != '' && orderDetailsList.skuListLink != null"
      		span="2" :label="$t('commonInfo.skuFile')">
      		<el-link type="primary" :href="orderDetailsList.skuListLink" target="_blank">
      			{{$t('commonInfo.actionDownload')}}</el-link>
      	</el-descriptions-item>
      </el-descriptions>
      <el-descriptions style="margin-bottom: 20px;" :column="2" border :labelStyle="{'width':'150px'}"
      	:title="$t('B2BOrder.cartonInfo')">
      </el-descriptions>
      <el-table style="margin:5px 10px;width: calc(100% - 10px);" :data="cartonList" border>
      	<!-- <el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="awbNo" width="140px"/> -->
      	<el-table-column :label="$t('commonInfo.packageNo')" align="center" prop="packageAwbNo" width="180px" />
      	<el-table-column :label="$t('commonInfo.index')" align="center" prop="packageIndex" />
      	<el-table-column :label="$t('commonInfo.length') + '(CM)'" align="center" prop="length" />
      	<el-table-column :label="$t('commonInfo.breadth') + '(CM)'" align="center" prop="breadth" />
      	<el-table-column :label="$t('commonInfo.height') + '(CM)'" align="center" prop="height" />
      	<el-table-column :label="$t('commonInfo.grossWeight') +'(KG)'" align="center" prop="grossWeight" />
      	<el-table-column :label="$t('commonInfo.chargeVolume') +'(CBM)'" align="center" prop="volume" />
      	<el-table-column prop="picUrl" min-width="100" :label="$t('commonInfo.photo')" align="center">
      		<template slot-scope="{row}">
      			<div v-if="row.picUrl != null && row.picUrl != ''">
      				<el-button type="text" @click="$parent.handleViewCartonImg(row.picUrl)" icon="el-icon-view">
      					{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
      			</div>
      			<span v-else></span>
      		</template>
      	</el-table-column>
      </el-table>
      <div class="pageBottom">
      	<el-pagination @size-change="handleCartonListSizeChange" @current-change="handleCartonListCurrentChange"
      		:current-page="cartonQuery.page" :page-sizes="[10, 20, 30, 40]"
      		:page-size="cartonQuery.limit" layout="total, sizes, prev, pager, next, jumper"
      		:total="total"></el-pagination>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CheckCartonInfo',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowData:{
        type:Object,
        default:function(){
        	return {};
        }
      },
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
          if(newValue){
            this.popup = newValue;
            this.getOrderInfo();
						this.getCartonList();
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
				cartonQuery:{
					page:1,
					limit:10,
				},
				cartonList:[],
        orderDetailsList:{},
        
        total:0,
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
        this.orderDetailsList = {};
				this.cartonList = [];
        this.$parent.checkOrderDetailPopup = false;
        // 清除数据
        this.$parent.rowData = {};
      },
			
			// 获取订单信息
			async getOrderInfo(){
				//订单详情
				const res = await this.$http.get("/toborder/sea/order/detail/" + this.rowData.id);
				if (res.code == 200) {
					this.orderDetailsList = res.data;
				}
			},
			
			// 分页条数
			handleCartonListSizeChange(size) {
				this.cartonQuery.limit = size;
				this.cartonQuery.page = 1;
				this.getCartonList();
			},
			// 分页页数
			handleCartonListCurrentChange(page) {
				this.cartonQuery.page = page;
				this.getCartonList();
			},
			// 获取箱列表
			async getCartonList(){
				const res = await this.$http.get(
					`/toborder/sea/package/list?awbNo=${this.rowData.awbNo}&page=${this.cartonQuery.page}&limit=${this.cartonQuery.limit}`
					)
				if (res.code == 200) {
					this.cartonList = res.data.list;
					this.total = res.data.totalCount;
				}
			},
			
			
    },
    created() {
    },

  }
</script>

<style scoped>
	.pageBottom {
		padding: 20px;
	}
</style>
