<template>
  <div>
    <!-- 查看订单轨迹信息 -->
    <el-dialog
    :title="$t('commonInfo.orderTrack')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="860px"
		class="timeline" 
    :before-close="handleDialogClose"
    >
      <div class="dialog_orderNo">
      	<label>{{ rowId }}</label>
      </div>
      <div class="dialog_extraInfo">
      	<div class="trackingOpt leave" v-if="leaveTracking != null">
      		<i class="el-icon-ship"></i>
      		<span>{{leaveTracking.description}}</span>
      		<span>{{leaveTracking.enDescription}}</span>
      	</div>
      	<div class="trackingOpt arrive" v-if="arriveTracking != null">
      		<i class="el-icon-s-home"></i>
      		<span>{{arriveTracking.description}}</span>
      		<span>{{arriveTracking.enDescription}}</span>
      	</div>
      </div>
      <el-timeline>
      	<el-timeline-item v-for="(activity, index) in activiData" :key="index" color="green"
      		:timestamp="(activity.trackingTime)" placement="top"
      		v-show="activity.status == 1000 ? false : true">
      		<el-card>
      			<!-- 备注 -->
      			<p style="font-size:16px;line-height: 30px;">
      				<span style="font-weight:700">Remarks：{{activity.description}}</span>
      			</p>
      			<!-- 轨迹区域 -->
      			<h4 style="color:#666;font-size:16px;line-height: 30px;">
      				[ {{activity.location}} ]
      				{{activity.enDescription}}
      			</h4>
      		</el-card>
      	</el-timeline-item>
      </el-timeline>
      <div slot="footer" class="dialog-footer">
      	<el-button type="primary" @click="handleDialogClose">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CheckTracking',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowId:{
        type:String,
        default:function(){
        	return '';
        }
      },
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
          if(newValue){
            this.popup = newValue;
            this.getTrackingList(this.rowId);
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
        activiData: [],
        // 轨迹显示修改
        leaveTracking: null,
        arriveTracking: null,
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
        
				this.activiData = [];
				this.leaveTracking = null;
				this.arriveTracking = null;
        this.$parent.checkSeaOrderTrackingPopup = false;
        // 清除数据
        this.$parent.rowId = '';
      },
			// 获取数据
			async getTrackingList(id) {
				const res = await this.$http.get("/toborder/sea/tracking/info/" + this.rowId);
				if (res.code == 200) {
					this.popup = true;
					// this.activiData = res.data;
					if (res.data && res.data.length > 0) {
						// 显示预计开船或预计到港需要判断轨迹是否已经存在已起航或到港的真实轨迹
						let trueLeave = res.data.filter(item => item.trackingStatus == 300);
						let trueArrive = res.data.filter(item => item.trackingStatus == 320);
				
						res.data.forEach(item => {
							// 预计开船和预计到港轨迹
							switch (item.trackingStatus) {
								case 230: //预计开船
									if (trueLeave.length == 0) { // 已有起航轨迹就不需要显示预计开船时间轨迹
										this.leaveTracking = item;
									}
									break;
								case 310: //预计到港
						   if (trueArrive.length == 0) { // 已有到港轨迹就不需要显示预计到港时间轨迹
						 			this.arriveTracking = item;
									}
									break;
								default:
									this.activiData.push(item);
									break;
							}
						})
					}
				}
			},
      
			
    },
    created() {
    },

  }
</script>

<style scoped>
	.dialog_orderNo {
		width: 100%;
		height: 50px;
		line-height: 40px;
		margin-bottom: 5px;
	}
	
	.dialog_orderNo label {
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 9px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	/* 子订单号 */
	.dialog_subOrderNo {
		width: 100%;
		height: 40px;
		line-height: 30px;
	}
	
	.dialog_subOrderNo label {
		margin-left: 20px;
		border-radius: 6px;
		font-size: 16px;
		padding: 5px 10px;
	}
	.dialog_extraInfo {
		width: 100%;
		padding: 15px 25px;
		margin-bottom: 5px;
	}
	
	.dialog_extraInfo label {
		margin: 10px 15px;
		font-weight: bold;
		border-radius: 8px;
		color: #5981c9;
		font-size: 18px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	
	.trackingOpt {
		display: inline-block;
		width: 500px;
		margin: 4px 15px;
		padding: 8px 10px;
		font-size: 14px;
		color: #ededed;
		border-radius: 6px;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	
	.trackingOpt i {
		font-size: 26px;
		display: block;
		float: left;
		width: 40px;
		height: 50px;
		line-height: 50px;
	}
	
	.trackingOpt span {
		width: 440px;
		line-height: 24px;
		display: block;
		float: left;
	}
	
	.leave {
		background-color: #01AAED;
		border: 1px solid #01AAED;
	}
	
	.arrive {
		background-color: #1e8f86;
		border: 1px solid #1e8f86;
	}
</style>
