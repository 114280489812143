<template>
  <div>
    <!-- 查看订单审核不通过原因 -->
    <el-dialog
		:lang="$i18n.locale"
    :title="$t('chinaWhB2BOrderPage.seaOrderFailedReason')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="860px"
		class="timeline" 
    :before-close="handleDialogClose"
    >
      <el-form :model="seaOrderRow" label-width="100px">
        <el-form-item :label="$t('commonInfo.orderNoColumn')" >
          <el-tag>{{seaOrderRow.awbNo}}</el-tag>
        </el-form-item>
      	<el-form-item v-if="seaOrderRow.reviewUrl != null && seaOrderRow.reviewUrl != ''" :label="$t('commonInfo.skuFile')" >
      	  <el-link type="primary" :href="seaOrderRow.reviewUrl">{{$t('commonInfo.actionDownload')}}</el-link>
      	</el-form-item>
        <el-form-item :label="$t('commonInfo.remark')" prop="reviewRemarks">
          <span class="parsingFilesFailedTips" v-html="returnParsingFilesFailedReason()"></span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogClose">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
        <el-button v-if="rowData.reviewStatus == '审核未通过'" type="primary" @click="handleTurnToModifyOrder">{{$t('commonInfo.modifyOrder')}}</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CheckTracking',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowData:{
        type:Object,
        default:function(){
        	return {};
        }
      },
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
          if(newValue){
            this.popup = newValue;
            this.seaOrderRow = JSON.parse(JSON.stringify(this.rowData));
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
        seaOrderRow:{},
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
        this.seaOrderRow = {};
				
        this.$parent.checkSeaOrderFailedPopup = false;
        // 清除数据
        this.$parent.rowData = {};
      },
			// 显示解析文件失败原因，优化显示
			returnParsingFilesFailedReason(){
				let html = '';
				html = '<label>'+ this.rowData.reviewRemarks +'</label>'
				// html += "<label>清单文件解析失败,请检查清单文件，请注意下列事项：</label>" +
				// "        <label>1、图片不要嵌入式，不能超出单元格，单元格只能有一张图片</label>" +
				// "        <label>2、申报单价、申报总金额 不要为空，若不清楚则填写0</label>" +
				// "        <label>3、检查文件是否有空白行以及特殊符号</label>" +
				// "        <label>4、单箱产品数量、SKU和总数量不能为空，多个相同图片的SKU请按多行填写</label>"
				
				return html;
			},
			// 跳转到修改订单窗口
			handleTurnToModifyOrder(){
				this.$parent.handleUpdateOrder(this.seaOrderRow);
				let that = this;
				setTimeout(function(){
					that.handleDialogClose();
				},300)
			},
			
      
			
    },
    created() {
    },

  }
</script>

<style scoped>
	/deep/.parsingFilesFailedTips label{
		display: block;
		color: #f91515;
	}
</style>
