<template>
	<div class="app-container" :lang="$i18n.locale">
		<div class="listHeader">
			<i></i><span>{{$t('B2BOrder.oceanShippingOrderList')}}</span>
		</div>
		<el-form style="margin:10px 0 0 20px" :model="queryParams" ref="queryParams" :inline="true"
			label-width="auto" @submit.native.prevent>
			<el-form-item >
				<el-input type="textarea" v-model="queryOrderNo"
					:placeholder="$t('commonInfo.orderNoColumn')" clearable size="small"
					style="width:220px;" />
			</el-form-item>
			<el-form-item >
				<el-input type="textarea" v-model="clientAwbNo"
					:placeholder="$t('commonInfo.clientOrderNoColumn')" clearable size="small"
					style="width:220px;" />
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="handleSearch">
					{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button type="primary" icon="el-icon-refresh" @click="getList()">
					{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
				</el-button>
				<el-button icon="el-icon-plus" type="primary" @click="addSeaOrder">
					{{$t('commonInfo.createSeaOrder')}}</el-button>
				<el-button icon="el-icon-download" @click="handleDownloadTemplate">
					{{$t('commonInfo.actionDownloadSeaSkuTemplate')}}</el-button>
			</el-form-item>
		</el-form>
		<el-table v-loading="loadingList" :data="seaOrderList" ref="tableList" border
		  :row-class-name="setSeaOrderClassName"
			style="width: 100%;font-size: 16px;">
			<el-table-column :label="$t('commonInfo.initTime')" align="center" prop="initTime"
				min-width="180px" />
			<el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="awbNo"
				min-width="180px" />
			<el-table-column :label="$t('commonInfo.clientOrderNoColumn')" align="center" prop="clientAwbNo"
				min-width="180px" />
			<el-table-column :label="$t('commonInfo.packageAmount')" align="center" prop="packageAmount"
				min-width="100" />
			<el-table-column :label="$t('B2BOrder.deCountry')" align="center" prop="destCountry"
				min-width="100" />
			<el-table-column :label="$t('B2BOrder.deType')" align="center" prop="destType" min-width="100" />
			<el-table-column :label="$t('chinaWhB2BOrderPage.shipType')" align="center" min-width="120"
				prop="reviewStatus">
				<template slot-scope="{row}">
					<span v-if="row.shipType === 1">{{$t('chinaWhB2BOrderPage.normalShip')}}</span>
					<span v-if="row.shipType === 2">{{$t('chinaWhB2BOrderPage.fastShip')}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('B2BOrder.examineStatus')" align="center" min-width="120"
				prop="reviewStatus">
				<template slot-scope="{row}">
					<label>{{row.reviewStatus}}</label>
					<div v-if="row.reviewRemarks != null">
						<el-button type="text" icon="el-icon-view" style="height: 22px; padding: 0px;"
							size="small" @click="handleCheckSeaOrderFailedReason(row)">
							{{$t('commonInfo.reviewRemark')}}</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.statusColumn')" align="center" prop="trackingStatus"
				width="160px" />
			<el-table-column label="POD" align="center" prop="podUrl">
				<template slot-scope="{row}">
					<div v-if="row.podUrlList.length > 0">
						<img :src="row.podUrlList[0]" @click="handlePOD(row.podUrlList)"
							style="width: 60px;height: 60px;" />
					</div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.operationColumn')" class-name="small-padding fixed-width"
				fixed="right" width="120" align="center" style="padding-right:0">
				<template slot-scope="{row}">
					<el-button size="mini" type="text" icon="el-icon-view" style="margin-left:10px"
						@click="orderTracking(row)">{{$t('commonInfo.actiontrack')}}</el-button>
					<el-button size="mini" type="text" icon="el-icon-view" @click="handleViewDetail(row)">
						{{$t('commonInfo.actionDetail')}}</el-button>
					<el-button size="mini" type="text" icon="el-icon-view"
						@click="handleCheckOrderDestInfo(row)">{{$t('chinaWhB2BOrderPage.destInfo')}}
					</el-button>
					<el-button size="mini" v-if="row.reviewStatus=='审核通过' && row.awbUrl==''" type="text"
						disabled icon="el-icon-loading">{{$t('commonInfo.awbGenerating')}}</el-button>
					<el-button v-if="row.reviewStatus != '审核通过'" size="mini" type="text" icon="el-icon-edit"
						style="margin-left:10px" @click="handleUpdateOrder(row)">
						{{$t('commonInfo.modifyOrder')}}</el-button>
					<el-button size="mini" v-if="row.reviewStatus=='审核通过' && row.awbUrl!=''" type="text"
						icon="el-icon-download" @click="handleDownloadAwb(row)">{{$t('commonInfo.faceNoodles')}}
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		
		<div class="pageBottom">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]"
				:page-size="queryParams.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="total"></el-pagination>
		</div>
		<div>
			<el-image-viewer style="z-index: 4000;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		</div>
		<!-- 创建修改订单和查看目的地信息窗口 -->
		<CreateOrder :isShow="createSeaOrderPopup" :rowData="rowData" :optType="optType"></CreateOrder>
		<!-- 查看轨迹 -->
		<CheckTracking :isShow="checkSeaOrderTrackingPopup" :rowId="rowId"></CheckTracking>
		<!-- 查看审核不通过 -->
		<CheckOrderFailedDetail :isShow="checkSeaOrderFailedPopup" :rowData="rowData"></CheckOrderFailedDetail>
		<!-- 查看订单详情 -->
		<CheckOrderDetail :isShow="checkOrderDetailPopup" :rowData="rowData"></CheckOrderDetail>
	</div>
</template>

<script>
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import CreateOrder from './createOrder.vue'
	import CheckTracking from './checkTracking.vue'
	import CheckOrderFailedDetail from './checkOrderFailedDetail.vue'
	import CheckOrderDetail from './checkOrderDetail.vue'
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	export default {
		components: {
			ElImageViewer,
			CreateOrder,
			CheckTracking,
			CheckOrderFailedDetail,
			CheckOrderDetail
		},
		data() {
			return {
				queryParams:{
					awbNoList: [],
					clientAwbNoList: [],
					page: 1,
					limit: 10,
				},
				seaOrderList:[],
				total:0,
				queryOrderNo:'',
				clientAwbNo:'',
				
				// 创建订单
				createSeaOrderPopup:false,
				rowData:{},
				optType:'',//add 新增，edit 修改
				
				detailedUrl:'',
				
				// 查看不通过原因
				checkSeaOrderFailedPopup:false,
				
				// 查看轨迹
				checkSeaOrderTrackingPopup:false,
				rowId:'',
				
				// 查看订单详情
				checkOrderDetailPopup:false,
				
				// 预览图片
				imgList: [],
				showImg: false,
			}
		},
		//方法集合
		methods: {
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getList();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getList();
			},
			// 获取字典
		  async	getDict(){
				// 获取海运清单模板
				const resTwo = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_template_url'
					}
				});
				
				if (resTwo.code === 200) {
					let that = this;
					let data = resTwo.rows;
					data.forEach(item => {
						if (item.dictLabel == 'B2B海运清单模板') {
							that.detailedUrl = item.remark;
						}
					})
				} else {
					this.$message.error(res.msg);
				}
			},
			// 渲染row
			setSeaOrderClassName({
			  row,
			  index
			}){
				let className = '';
				if(row.reviewStatus == '审核未通过'){
					className = 'reviewFailed'
				}
				return className
			},
			// 搜索海运订单
			handleSearch() {
				if (this.queryOrderNo != '') {
					this.queryParams.awbNoList = this.strToArr(this.queryOrderNo);
				} else {
					this.queryParams.awbNoList = [];
				}
				
				if (this.clientAwbNo != '') {
					this.queryParams.clientAwbNoList = this.strToArr(this.clientAwbNo);
				} else {
					this.queryParams.clientAwbNoList = [];
				}
				this.queryParams.page = 1;
				this.getList();
			},
			// 重置
			handleReset() {
				this.queryOrderNo = '';
				this.clientAwbNo = '';
				this.queryParams = {
					awbNoList: [],
					clientAwbNoList: [],
					page: 1,
					limit: 10,
				}
				this.getList();
			},
			// 获取列表
			async getList() {
				this.loadingList = true;
				this.$http.post("/toborder/sea/order/list", this.queryParams).then(res => {
					if (res.code == 200) {
						this.total = res.data.totalCount;
						this.loadingList = false;
						this.seaOrderList = res.data.list;
					} else {
						this.loadingList = false;
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.loadingList = false;
					this.$message.error(err.msg);
				})
			},
			//关闭图片
			closeImg() {
				this.showImg = false;
			},
			// 查看箱图片
			handleViewCartonImg(url) {
				this.imgList = [];
				this.imgList.push(url);
				this.showImg = true;
			},
			
			// 查看海运订单审核不通过原因
			handleCheckSeaOrderFailedReason(row){
				this.checkSeaOrderFailedPopup = true;
				this.rowData = row;
			},
			//查看POD
			handlePOD(list) {
				this.imgList = [];
				var that = this;
				if (list.length > 0) {
					this.showImg = true;
					list.forEach(item => {
						that.imgList.push(item);
					})
				} else {
					this.$message.error("暂无数据");
				}
				// console.log(this.PodPreList);
			},
			
			// 新增海运订单
			addSeaOrder(){
				this.createSeaOrderPopup = true;
				this.optType = 'add';
			},
			// 修改海运订单
			handleUpdateOrder(row){
				this.createSeaOrderPopup = true;
				this.optType = 'edit';
				this.rowData = row;
			},
			// 下载海运清单模板
			handleDownloadTemplate(){
				window.open(this.detailedUrl);
			},
			
			// 查看轨迹
			orderTracking(row){
				this.checkSeaOrderTrackingPopup = true;
				this.rowId = row.awbNo;
			},
			// 查看订单详情
			handleViewDetail(row){
				this.checkOrderDetailPopup = true;
				this.rowData = row;
			},
			// 目的地信息
			handleCheckOrderDestInfo(row){
				this.createSeaOrderPopup = true;
				this.optType = 'check';
				this.rowData = row;
			},
			// 下载面单
			handleDownloadAwb(row) {
				window.open(row.awbUrl);
			},
			
		},
		created() {
			this.getList();
			this.getDict();
		},
	}
</script>

<style scoped>
	.app-container {
		padding: 10px;
	}
	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	
	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	
	.listHeader span {
		display: inline-block;
		float: left;
	}
	/deep/ .reviewFailed{
		background-color: #ffe9e9;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
	}
</style>